import 'bootstrap/js/dist/modal';
import {isEmpty, isNull} from "lodash";
import axios from "axios";

window.initializePlayerModal = (modal: Element) => {
    modal.addEventListener('show.bs.modal', () => {
        const playerElement = modal.querySelector('#mux-player') as HTMLElement;
        const player = document.createElement('mux-player');

        if (playerElement === null) {
            return;
        }
        if (playerElement.dataset.isMuted === '1') {
            player.setAttribute('muted', playerElement.dataset.isMuted ?? '0');
        }

        player.setAttribute('hotkeys', playerElement.dataset.isMuted === '1' ? 'nom' : '');
        player.setAttribute('stream-type', playerElement.dataset.streamType ?? 'on-demand');
        player.setAttribute('playback-id', playerElement.dataset.playbackId ?? '');
        player.setAttribute('metadata-video-id', player.dataset.metadataVideoId ?? '');
        player.setAttribute('metadata-video-title', player.dataset.metadataVideoTitle ?? '');
        player.addEventListener('play', function () {
            const data = sessionStorage.getItem(playerElement.dataset.mediaId ?? '');

            if (isNull(data)) {
                sessionStorage.setItem(playerElement.dataset.mediaId ?? '', 'viewed');

                axios.put('api/media/' + playerElement.dataset.mediaId, {
                    media_id: playerElement.dataset.mediaId,
                });
            }
        });

        playerElement.replaceWith(player);
    });

    modal.addEventListener('hidden.bs.modal', () => {
        const player = modal.querySelector('mux-player');
        (player as any)?.pause();
    });
}

const videoModalElements = document.querySelectorAll('.video-modal');

videoModalElements.forEach(element => {
    window.initializePlayerModal(element);
});

const instantlyLoadMuxElements = document.querySelectorAll('.instantly-load-mux-player');

instantlyLoadMuxElements.forEach(element => {
    const player = document.createElement('mux-player');
    const mediaId = element.dataset.mediaId;

    if ((element as HTMLElement).dataset.isMuted === '1') {
        player.setAttribute('muted', (element as HTMLElement).dataset.isMuted ?? '0');
    }

    player.setAttribute('media-id', (element as HTMLElement).dataset.mediaId ?? '');
    player.setAttribute('hotkeys', (element as HTMLElement).dataset.isMuted === '1' ? 'nom' : '');
    player.setAttribute('stream-type', (element as HTMLElement).dataset.streamType ?? 'on-demand');
    player.setAttribute('playback-id', (element as HTMLElement).dataset.playbackId ?? '');
    player.setAttribute('metadata-video-id', player.dataset.metadataVideoId ?? '');
    player.setAttribute('metadata-video-title', player.dataset.metadataVideoTitle ?? '');

    player.addEventListener('play' ,function () {
        if (mediaId !== undefined){
            const data = sessionStorage.getItem(mediaId);

            if (isNull(data) || isEmpty(data)) {
                sessionStorage.setItem(mediaId ?? '', 'viewed');

                axios.put('/api/media/' + mediaId, {
                    media_id: mediaId,
                });
            }
        }
    });

    element.replaceWith(player);
});

import './video-player';
import './bootstrap';
import '@mux/mux-player';
import LazyLoad from "vanilla-lazyload";
import axios from "axios";
import {Alert} from "bootstrap";
import {isNil} from "lodash";

import './video-player';
import './toast';
import Swal from "sweetalert2";

const lazyLoadInstance = new LazyLoad({
    callback_loaded: element => {
        const container = element.closest('.video-element');

        if (isNil(container)) {
            return;
        }

        container.querySelector('#video-placeholder')?.remove();
        container.querySelector('#video')?.classList.remove('d-none');
    },
});

lazyLoadInstance.loadAll();

window.api = axios.create({
    baseURL: window.global.api_url,
    withCredentials: true,
});

window.axios = axios.create({
    withCredentials: true,
});

document.addEventListener("DOMContentLoaded", function(){
    const navbar = document.getElementById('main-navbar');

    if (navbar === null) {
        return;
    }

    const main = (document.getElementById('main') as HTMLElement);

    if (main) {
        main.style.minHeight = document.body.clientHeight - navbar.clientHeight + 'px';
    }

    window.addEventListener('scroll', function() {
        if (window.scrollY > navbar.offsetHeight) {
            navbar.classList.add('fixed-top');
            // Add padding top to show content behind navbar
            document.body.style.paddingTop = navbar.offsetHeight + 'px';
        } else {
            navbar.classList.remove('fixed-top');
            // Remove padding top from body
            document.body.style.paddingTop = '0';
        }
    });
});

new Alert('#setup-alert');

window.setupDownloadMedia = (element: HTMLElement) => {
    if (isNil(element)) {
        return;
    }

    if (element.dataset.initialized == 'true') {
        return;
    }

    element.addEventListener('click', event => {
        const element = event.target instanceof HTMLButtonElement ? event.target : (event.target as HTMLElement).closest('button');

        const mediaToDownload = (element as HTMLElement).dataset.media;

        (element as HTMLButtonElement).disabled = true;

        window.axios.get('download/' + mediaToDownload)
            .then(response => {
                if (isNil(response.data.url)) {
                    Swal.fire('success', 'Your download has started in the background. You will receive an email with the download URL.', 'success');
                } else {
                    fetch(response.data.url)
                        .then(response => response.blob())
                        .then(blob => {
                            const href = URL.createObjectURL(blob);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', (element as HTMLButtonElement).dataset.name ?? 'media.mp4');
                            link.click();

                            URL.revokeObjectURL(href); // Prevent memory leak
                        });
                }
            })
            .catch(e => {
                if (e.response.data.message === 'max_downloads_reached') {
                    Swal.fire(
                        'error',
                        window.translations['en']['site.alerts.exceeded_download_limit']
                            .replace('{downloads_today}', e.response.data.downloads_today)
                            .replace('{max_downloads}', e.response.data.max_downloads),
                        'error'
                    );
                }
            })
            .catch(e => {
                if (e.response.data.message === 'max_downloads_reached') {
                    Swal.fire(
                        'error',
                        window.translations['en']['site.alerts.exceeded_download_limit']
                            .replace('{downloads_today}', e.response.data.downloads_today)
                            .replace('{max_downloads}', e.response.data.max_downloads),
                        'error'
                    );
                }
            })
            .finally(() => {
                (element as HTMLButtonElement).disabled = false;
            });
    });

    element.dataset.initialized = 'true';
}

import {Toast} from 'bootstrap';

const toastTrigger = document.getElementById('share-button');
const liveToast = document.getElementById('liveToast');
const toastContainer = document.getElementById('toastContainer');

toastTrigger?.addEventListener('click', () => {
    toastContainer?.classList.remove('d-none');
    const toastBootstrap = Toast.getOrCreateInstance(liveToast);

    toastBootstrap.show();
});

